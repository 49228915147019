import { Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

const d = new Date();
const y = d.getFullYear();

const Footer = () => {
  return (
    <Row className="footer" style={{minHeight: '200px', marginTop: "25px", textAlign: "center"}}>
      <hr />
      <p>OpenSource Cafe Ltd: <a href="https://www.linkedin.com/company/opensource-cafe" target="_blank" rel="noreferrer"><Icon.Linkedin /></a>&nbsp;
        <a href="mailto:craig@opensource.cafe"><Icon.EnvelopeAtFill />: craig@opensource.cafe</a><br />
        Rock Universe: <a href="https://www.facebook.com/rockuniverseuk" target="_blank" rel="noreferrer"><Icon.Facebook /></a>&nbsp;
        <a href="https://www.instagram.com/rockuniverseuk" target="_blank" rel="noreferrer"><Icon.Instagram /></a>&nbsp;
        <a href="https://www.pinterest.co.uk/rockuniverseuk" target="_blank" rel="noreferrer"><Icon.Pinterest /></a>&nbsp;
        <a href="mailto:messages@rockuniverse.co.uk"><Icon.EnvelopeAtFill />: messages@rockuniverse.co.uk</a><br />
        smellyourmum.com:&nbsp;
        <a href="https://www.facebook.com/smellyourmumdotcom" target="_blank" rel="noreferrer"><Icon.Facebook /></a>&nbsp;
        <a href="https://www.instagram.com/smellyourmum" target="_blank" rel="noreferrer"><Icon.Instagram /></a>&nbsp;
        <a href="mailto:shop@smellyourmum.com"><Icon.EnvelopeAtFill />: shop@smellyourmum.com</a><br />
        Call or whatsapp on: 07554174540
      </p>

      <p className="small-text">©{y} OpenSource Cafe Ltd Registered in England and Wales; Company number 11540554; Registered address 38 
      Southgate Drive, Crawley, West Sussex, RH10 6HB.</p>
      <div style={{position: "fixed", bottom: "10px", right: "5px", textAlign: "right"}} className="d-md-none">
        <a href="#home"><Icon.ArrowBarUp />
          <p className="small-text">Back to top</p>
        </a>
      </div>
    </Row>
  )
}

export default Footer;