import { Row, Col } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import shopify from '../images/ShopifyPartners_Primary.png';
import sielAbrim from '../images/sielabrim-logo.png';

type SiteProperty = {
  description: string;
  included: boolean;
};

const included = (props: SiteProperty[]) => {
  return (
    <table className="property-column">
      <tbody>        
        {props.map(p => (<tr key={p.description}><td>{p.description}</td>
          <td>{p.included?<Icon.CheckCircleFill
        color="green" />:<Icon.XCircleFill
        color="red" />}</td></tr>))}
      </tbody>    
    </table>
  )
};
  

const WebServices = () => {
  return(
    <>
      <Row>
        <h3 id="website">Website Services</h3>
      </Row>
      <Row>
        <p>
          Our website services are available for small businesses, including the self employed to give you an online 
          presense. You can use your site to show off your services or have a complete eCommerce web application created, 
          depending on your requirements. We can create you a completely bespoke website and host it for you. We can design 
          and configure a Content Management System (CMS) such as wordpress on your behalf.
        </p>
        <p>
          We are offical <a href="www.fasthosts.co.uk" target='_blank' rel='noreferrer'>Fasthosts Solution Partners</a> and 
          offer server, hosting, email and many more packages to suit your needs and budget. Fasthosts offer a great range 
          and using us gives you even better benefits than they already offer.
        </p>
        <p>
          Last but not least we are official Shopify partners, meaning we can build you a fully hosted eCommerce web 
          application using a well established platform and even create new and bespoke extensions especially for your store 
          and requirements.
        </p>
      </Row>
      <Row>
        <Col sm={4}>
          <h6>Basic</h6>
          {included([
            {description: "Single page website", included: true},
            {description: "Page menu", included: true},
            {description: "Image gallery", included: true},
            {description: "Multi page webite", included: false},
            {description: "Contact form", included: false},
            {description: "Booking system", included: false},
            {description: "Content management", included: false},
            {description: "Online payments", included: false},
            {description: "Product management", included: false},
          ])}
        </Col>
        <Col sm={4}>
          <h6>Functional</h6>
          {included([
            {description: "Single page website", included: true},
            {description: "Page menu", included: true},
            {description: "Image gallery", included: true},
            {description: "Multi page webite", included: true},
            {description: "Contact form", included: true},
            {description: "Booking system", included: true},
            {description: "Content management", included: true},
            {description: "Online payments", included: false},
            {description: "Product management", included: false},
          ])}
        </Col>
        <Col sm={4}>
          <h6>Professional</h6>
          {included([
            {description: "Single page website", included: true},
            {description: "Page menu", included: true},
            {description: "Image gallery", included: true},
            {description: "Multi page webite", included: true},
            {description: "Contact form", included: true},
            {description: "Booking system", included: true},
            {description: "Content management", included: true},
            {description: "Online payments", included: true},
            {description: "Product management", included: true},
          ])}
        </Col>
      </Row>
      <Row><p className="small-text">Prices to be quoted during requirement scoping and may vary. The above packages are 
      for example only and any features could be made available.</p></Row>
      <Row>
        <h4>Support Packages</h4>
        <p>Once your site is up and running you can chose one of support pacakges to keep it that way!</p>
        <Col sm={3}>
          <table className='support-block'>
            <thead>
              <td>3 Hours Per Month</td>
            </thead>
            <tr><td>£195 monthly payment for 12 months</td></tr>
            <tr><td>£2240 annual payment (save £100)</td></tr>
          </table>
        </Col>
        <Col sm={3}>
          <table className='support-block'>
            <thead>
              <td>5 Hours Per Month</td>
            </thead>
            <tr><td>£300 monthly payment for 12 months</td></tr>
            <tr><td>£3450 annual payment (save £150)</td></tr>
          </table>
        </Col>
        <Col sm={3}>
          <table className='support-block'>
            <thead>
              <td>10 Hours Per Month</td>
            </thead>
            <tr><td>£550 monthly payment for 12 months</td></tr>
            <tr><td>£6300 annual payment (save £300)</td></tr>
          </table>
        </Col>
        <Col sm={3}>
          <table className='support-block'>
            <thead>
              <td>20 Hours Per Month</td>
            </thead>
            <tr><td>£1000 monthly payment for 12 months</td></tr>
            <tr><td>£11400 annual payment (save £600)</td></tr>
          </table>
        </Col>
        <p className="small-text">Any time worked above the contracted hours will be charged at £65 per hour.</p>
        <p>All packages include:</p>
        <ul style={{"marginLeft": "25px"}}>
          <li>Platform features and security updates</li>
          <li>Keeping the website on-line and fixing any issues or errors</li>
          <li>Updating content on existing pages (including fonts, formatting, images, etc)</li>
          <li>Adding pages and content</li>
          <li>SEO updates</li>
          <li>Data and website backups</li>
          <li>Email configuration and updates</li>
          <li>Domain configuration and updates</li>
          <li>Other tasks that help with the day to day running of your website that have not been listed</li>
          <li>* Support via email, phone and online meeting app/s</li>
        </ul>
        <p className="small-text">* during office open times</p>
      </Row>
      <Row style={{margin: '10px 0'}}>
        <Col>
          <a href="https://www.fasthosts.co.uk/partner-network/profiles/opensource-cafe/?utm_source=partnerprogramme&utm_medium=referral"
           target='_blank' rel='noreferrer'>
            <img src="https://static.fasthosts.co.uk/partners/badges/fh-solutions-partner-c83261.svg" width="100%" alt="Fasthosts Solutions Partner" />
          </a>
        </Col>
        <Col>
          <img src={shopify} alt="Official Shopify Partner" width="100%" />
        </Col>
      </Row>
      <Row>
        <h4>Projects completed:</h4>
        <Col>
          <p><a href="https://www.sielabrim-souloverflow.uk" target='_blank'
            rel='noreferrer'><img src={sielAbrim} alt="SielAbrim" height="200px" /></a></p>
        </Col>
      </Row>
    </>
  )
}

export default WebServices;