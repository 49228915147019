import { Row, Col } from 'react-bootstrap';
import me from '../images/me2.jpg'

const ExperienceList = (heading: string, list: string[]) => {
  return (
    <>
      <h6>{heading}</h6>
      <ul>
        {list.map(v => (<li key={v}>{v}</li>))}
      </ul>
    </>
  );
}

const Contract = () => {
  return(
    <>
      <Row>
        <h2 id="contract">Contracting/Consulting</h2>
      </Row>
      <Row>
        <Col sm={3}>
          <img src={me} alt="Craig Worsell" height="250"/>
          <p className="small-text">Craig Worsell, Director</p>
        </Col>
        <Col>
        <p>Our director, Craig Worsell, has over 12 years backend development and 6 years frontend experience. He has worked 
          as a senior developer for large corporations and smaller start ups, and as a Lead Developer for a large 
          multinational insurance company, where he managed and mentored a small team. He is available for contract roles, 
          and is looking forward to working with you on your next project. He is available for an immediate start in the 
          South East/London area. Please contact us for daily rates and location inquiries. We are happy to have direct 
          contact with hiring companies or recruiters looking for contractors.<br />
          View his profile on linkedin @ <a href="https://www.linkedin.com/in/craig-worsell-3595545b/" target='_blank'
          rel='noreferrer'>https://www.linkedin.com/in/craig-worsell-3595545b/</a>
        </p>
        </Col>
      </Row>
      <Row>
        <h5>Technologies and Experience</h5>
        <Col sm={3}>
          {ExperienceList("Backend",
            [
              "Java 8+",
              "Spring/Spring boot",
              "Hibernate",
              "Junit/Mockito",
              "SQL",
              "Oracle DB",
              "PostGRES",
              "SQL Server",
              "MySql",
              "NoSql",
              "MongoDB",
              "DynamoDB",
              "Kafka",
              "ActiveMQ",
              "Maven",
              "Tomcat"
            ]
          )}
        </Col>
        <Col sm={3}>
        {ExperienceList("Frontend",
            [
              "Reactjs",
              "Redux",
              "Angularjs",
              "Graphql",
              "Javascript",
              "jQuery",
              "html",
              "CSS",
              "nodejs",
              "npm",
              "yarn",
            ]
          )}
        </Col>
        <Col sm={3}>
        {ExperienceList("CI/CD",
            [
              "Jenkins",
              "Ansible",
              "Git",
              "SVN",
              "Artifactory",
              "AWS",
            ]
          )}
        </Col>
        <Col sm={3}>
        {ExperienceList("Other",
            [
              "S.O.L.I.D",
              "TDD",
              "DDD",
              "Agile (Scrum/Kanban)",
              "Trello",
              "Jira",
              "Microservices",
              "APIs",
            ]
          )}
        </Col>
      </Row>
    </>
  );
}

export default Contract;