import { Row, Col, Carousel } from 'react-bootstrap';
import ruLogo from "../images/ru_logo.png";
import symLogo from "../images/sym_logo.png";

const carouselItem = (imgLogo: any, url: string, altText: string, height: string) => {
  return (
    <Carousel.Item>
      <div style={{width: "100%", backgroundColor: "black", textAlign: "center", minHeight: "400px", display: "flex",
        justifyContent: "center", alignItems: "center"}}>
        <a href={url} target="_blank" rel="noreferrer"><img src={imgLogo} alt={altText} height={height} /></a>
      </div>
    </Carousel.Item>
  )
}

const Retail = () => {
  return (
    <>
      <Row>
        <h3 id="ecomm">Our eCommerce Sites</h3>
        <Carousel>
          {carouselItem(ruLogo, "http://rockuniverse.co.uk", "Rock Universe", "86px")}
          {carouselItem(symLogo, "http://offensiveclothing.com", "smellyourmum.com", "250px")}
        </Carousel>
      </Row>
      <Row className="site-text">
        <Col>
          <p><a href="http://rockuniverse.co.uk" target="_blank" rel="noreferrer"><strong>rockuniverse.co.uk</strong></a> 
          &nbsp;sells Rock Off officially licensed merchandise. Find officially licensed mechandise from your favourite artist, 
          band, TV show or movies. We have T-Shirts, Hoodies, Patches and many more products available, all at a good 
          price.</p>
        </Col>
        <Col>
          <p><a href="http://offensiveclothing.com" target="_blank" rel="noreferrer"><strong>smellyourmum.com</strong></a> 
          &nbsp;est 2002, is the original and best place to find outrageous, funny and offensive clothing. We print all 
          products to order and we warn you now, DO NOT come to this site if you are easily offended, you will NOT like our 
          designs! 18+.</p>
        </Col>
      </Row>
    </>
  );
}

export default Retail;