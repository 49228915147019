import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './images/logo.png';
import Menu from './components/Menu';
import About from './components/About';
import Contract from './components/Contract';
import ContactForm from './components/ContactForm';
import WebServices from './components/WebServices';
import Retail from './components/Retail';
import Footer from './components/Footer';
import './App.css';


function App() {
  return (
    <Container>
      <Row className="logo-container">
        <img id="home" src={logo} width="660px" height="250px" alt="osc logo" />
      </Row>
      <Row>
        <Col sm={3} style={{"backgroundColor": "aliceblue", "padding": "20px 0"}}>
          <Menu />
        </Col>
        <Col sm={9} style={{minHeight: "900px", "backgroundColor": "aliceblue", "padding": "20px 0"}}>
          <About />
          <WebServices />
          <Contract />          
          <Retail />
          <ContactForm />       
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}

export default App;
