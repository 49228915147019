import { Row } from 'react-bootstrap';

const About = () => {
  return (
    <Row>
      <h2 id="about">Who are we?</h2>
      <p>We are small company specialising in web application/software development from Crawley, West Sussex. We offer our 
        services to businesses and the self employed needing a small online presence, to large corporations who require an 
        experienced software developer to join their team for a major project and everything inbetween.
      </p>
      <p>Our main priority is doing the right thing for our Customers, making sure the end product fits the dream that was 
        originally envisioned by you. We will ensure good communication and updates during the development process and be 
        approachable.
      </p>
      <p>We also offer contracting/consulting services for larger companies at fair daily rates. We will integrate and work 
        well with any established team you have or be able to take on an entire project so your or your team can continue 
        the amazing work you are already doing.
      </p>
      <p>Finally, we own two eCommerce web applications selling clothing and accessories, find out more below.</p>
    </Row>
  )
}

export default About;