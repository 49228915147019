import { useState } from 'react';
import { Row, Col, Button, Form, Toast, ToastContainer, Spinner} from 'react-bootstrap';
import * as formik from 'formik';
import * as yup from 'yup';

const ContactForm =() => {

  const [showA, setShowA] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [variant, setVariant] = useState("success");
  const toggleShowA = () => setShowA(!showA);
  const [loading, setLoading] = useState(false);

  const { Formik } = formik;

  async function processForm(values: any, resetForm: Function) {
    setLoading(true);
    try {
      const response = await fetch("https://api.opensource.cafe/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
  
      const result = await response.json();
      if (result.successful) {
        console.log("Success:", result);
        setToastMessage(
          'Thanks you, your message has been sent, we aim to respond within 24 hrs.');
        setVariant("success")
        setShowA(true);
        resetForm();
      } else {
        console.error("Error:", result.message);
        setToastMessage(
          'Unfortunately your message could not be sent at this time, please try again or alternatively send us an email instead.');
        setVariant("danger")
        setShowA(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setToastMessage(
        'Unfortunately your message could not be sent at this time, please try again or alternatively send us an email instead.');
      setVariant("danger")
      setShowA(true);
    }
    setLoading(false);
  }

  const spinner = () => {
    return(
      <>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />&nbsp;Sending...
      </>
    )
  }

  const schema = yup.object().shape({
    firstName: yup.string().required("first name is required"),
    lastName: yup.string().required("last name is required"),
    email: yup.string().required().email(),
    service: yup.string().required(),
    message: yup.string().required(),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values, {resetForm}) => {
        processForm(values, resetForm);
      }}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        service: '',
        message: '',
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <>
          <h3 id="contact">Contact us</h3>
          <Form id="contact" noValidate onSubmit={handleSubmit}>
              <Form.Group as={Row} className="mb-3" controlId="validationFormikName">
                <Form.Label column sm={2}>First name</Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    name="firstName"
                    placeholder="First name"
                    value={values.firstName}
                    onChange={handleChange}
                    isInvalid={!!errors.firstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="validationFormikSurname">
                <Form.Label column sm={2}>Last name</Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="Last name"
                    value={values.lastName}
                    onChange={handleChange}
                    isInvalid={!!errors.lastName}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="validationFormikEmail">
                <Form.Label column sm={2}>Email</Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="validationFormikService">
                <Form.Label column sm={2}>Service</Form.Label>
                <Col sm={6}>
                <Form.Select aria-label="Service Selection"
                  name="service"
                  value={values.service}
                  onChange={handleChange}
                  isInvalid={!!errors.service}>
                  <option value="">Please select</option>
                  <option value="contracts">OpenSouce Cafe - Contracting</option>
                  <option value="websites">OpenSource Cafe - Website services</option>
                  <option value="rockuniverse">eCommerce - Rock Universe</option>
                  <option value="smellyourmum">eCommerce - smellyourmum.com</option>
                </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.service}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="validationFormikMessage">
                <Form.Label column sm={2}>Message</Form.Label>
                <Col sm={6}>
                  <Form.Control
                    as="textarea"
                    placeholder="Message"
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                    isInvalid={!!errors.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.message}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            <Button variant="secondary" type="submit">{loading?
              spinner():"Submit form"}
            </Button>
          </Form>
          <ToastContainer
            className="p-3"
            position="middle-center"
            style={{ zIndex: 1, position: 'fixed'}}
          >
            <Toast show={showA} onClose={toggleShowA} bg={variant}>
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Contact Form</strong>
            </Toast.Header>
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </ToastContainer>
        </>
      )}
    </Formik>
  );
}

export default ContactForm;