import { Dropdown, Nav, NavItem, NavLink } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

const Menu = () => {
  return(
    <>
      <div className="menu d-none d-md-block">
        <Nav defaultActiveKey="" className="flex-column">
          <Nav.Link href="#about">Who are we?</Nav.Link>
          <Nav.Link href="#website">Website Services</Nav.Link>
          <Nav.Link href="#contract">Contracting/Consulting</Nav.Link>
          <Nav.Link href="#ecomm">Our eCommerce Sites</Nav.Link>
          <Nav.Link href="#contact">Contact us</Nav.Link>
        </Nav>
      </div>
      <div className="d-md-none">
        <Dropdown as={NavItem}>
          <Dropdown.Toggle as={NavLink}><Icon.List /></Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#about">Who are we?</Dropdown.Item>            
            <Dropdown.Item href="#website">Website Services</Dropdown.Item>
            <Dropdown.Item href="#contract">Contracting/Consulting</Dropdown.Item>
            <Dropdown.Item href="#ecomm">Our eCommerce Sites</Dropdown.Item>
            <Dropdown.Item href="#contact">Contact us</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  )
}

export default Menu;